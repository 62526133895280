/* eslint-disable */
import { FORMATS } from "@/shared/services/formats";
import * as d3 from "d3";
import moment, { Moment } from "moment";
import { ObservedPeriodDto } from "../../../store/Dtos/ObservedPeriodDto";
import { MachineDetail, RunDto } from "@/store/Dtos";

interface MachineRun {
  machineId: string;
  runs: RunDto[];
}

export class CTWOeeAvailability {
  // fields & constructor
  private svg: any;
  private resizeHandler: any = null;
  private initDone = false;
  private dim: any = { w: 0, h: 0 };
  private margin: any = { l: 16, r: 16, t: 8, b: 16 };
  private layout: any = {
    paddingAxes: 4,
    heightBars: 32,
  };
  private t: any;
  private x: any;
  private y: any;
  private observedPeriods: ObservedPeriodDto[] = [];
  private runs: RunDto[] = [];
  private usedMachineIds: string[] = [];
  private machines: MachineDetail[] = [];
  private productionOrderName!: string;
  private clickHandler: (run: RunDto) => void;
  constructor(container: string, machines: MachineDetail[], productionOrderName: string, clickHandler: (run: RunDto) => void) {
    this.productionOrderName = productionOrderName;
    this.machines = machines;
    this.clickHandler = clickHandler;
    this.svg = d3.select(`svg#${container}`);
    this.init();
  }
  public destroy() {
    window.removeEventListener("resize", this.resizeHandler);
  }

  // public methods
  public setTimeRange(start: Moment, end: Moment) {
    if (!this.initDone) return;

    this.x.domain([start.valueOf(), end.valueOf()]);
  }

  public update(observedPeriods: ObservedPeriodDto[], runs: RunDto[], usedMachineIds: string[]) {
    if (!this.initDone) return;

    // update values
    this.runs = runs;
    this.observedPeriods = observedPeriods;
    this.usedMachineIds = usedMachineIds;



    const machineNames = usedMachineIds.map((machineId: string) =>
      this.getMachineName(machineId)
    );

    // adjust svg height
    const newHeight = usedMachineIds
      ? Math.max(
        this.margin.b + usedMachineIds.length * this.layout.heightBars,
        150
      )
      : 150;
    if (this.dim.h !== newHeight) {
      this.svg.style("height", newHeight);
      this.resize();
      return;
    }

    // update x-axis
    this.svg.select("g.x-axis").call(d3.axisBottom(this.x));

    // skip execution when series not set
    if (this.observedPeriods.length === 0) return;
    const t = d3.transition().duration(500);

    // update y-axis

    this.y.domain(machineNames);
    this.svg.select("g.y-axis").call(d3.axisLeft(this.y));
    this.svg
      .select("g.y-axis")
      .selectAll("text")
      .transition(t)
      .attr("text-anchor", "start")
      .attr("font-size", "14")
      .attr(
        "transform",
        `translate(${-6 + this.margin.l + this.layout.paddingAxes + 4}, 0)`
      );

    // valid periods

    const sobservedPeriods = this.svg
      .select("g.valid-periods")
      .selectAll("rect")
      .data(this.observedPeriods);
    const sobservedPeriodsEnter = sobservedPeriods
      .enter()
      .append("rect")
      .attr("x", this.dim.w / 2)
      .attr("y", this.dim.h);
    sobservedPeriods
      .merge(sobservedPeriodsEnter)
      .transition(t)
      .attr("x", (d: ObservedPeriodDto) => this.x(moment(d.from).valueOf()))
      .attr("y", this.margin.t)
      .attr(
        "width",
        (d: ObservedPeriodDto) =>
          this.x(moment(d.until).valueOf()) - this.x(moment(d.from).valueOf())
      )
      .attr(
        "height",
        this.dim.h - this.margin.t - this.margin.b - this.layout.paddingAxes
      );
    sobservedPeriods.exit().remove();

    // drawing the runs
    const runsPerMachine = this.runsToMachineRuns(runs);

    const self = this;
    const sSeries = this.svg
      .select("g.series")
      .selectAll("g")
      .data(runsPerMachine, (d: any) => {
        return d.machineId
      });
    const sSeriesEnter = sSeries
      .enter()
      .append("g")
      .attr("id", (d: MachineRun) => `serie-${d.machineId}`);
    const sSerieRects = sSeries
      .merge(sSeriesEnter)
      .selectAll("rect")
      .data(
        (d: MachineRun) => d.runs,
        (d: RunDto) => `${d.machineId}-${d.from}`
      );
    sSeries.exit().remove();
    const sSerieRectsEnter = sSerieRects
      .enter()
      .append("rect")
      .attr("x", this.dim.w / 2)
      .attr("y", this.dim.h)
      .on("mouseover", (_: any, run: RunDto) => {
        const machine = self.getMachine(run.machineId);
        if (machine == null) return;
        this.svg
          .select("g.tooltip text.start")
          .text(`Start: ${moment(run.from).format(FORMATS.dateHMS)}`);
        this.svg
          .select("g.tooltip text.stop")
          .text(`Stop: ${moment(run.until).format(FORMATS.dateHMS)}`);

        let performance = "100.0";

        if (run.expectedProduction > 0)
          performance = (
            (100 * run.produced) /
            run.expectedProduction
          ).toFixed(1);
        const performanceText = `Performance: ${run.produced.toFixed(1)} ${machine.unitOfProduction} / ${run.expectedProduction.toFixed(1)} ${machine.unitOfProduction} (${performance} %)`;

        this.svg.select("g.tooltip text.performance").text(performanceText);

        this.svg.select("g.tooltip text.production-order").text(`${this.productionOrderName}: ${run.productionOrderId ?? '-'}`);

        this.svg
          .select("g.tooltip")
          .transition()
          .duration(500)
          .attr("visibility", "visible");
        const size = this.svg
          .select("g.tooltip g.content")
          .node()
          .getBBox();
        this.svg
          .select("g.tooltip rect.bg")
          .attr("x", -4)
          .attr("y", -size.height)
          .attr("width", size.width + 8)
          .attr("height", size.height + 8);
      })
      .on("mouseout", () => {
        this.svg
          .select("g.tooltip")
          .transition()
          .duration(500)
          .attr("visibility", "hidden");
      })
      .on("mousemove", (evt: Event) => {
        const size = this.svg
          .select("g.tooltip g.content")
          .node()
          .getBBox();
        const mouse = d3.pointer(evt, this.svg.node());
        this.svg.select("g.tooltip").attr("transform", () => {

          const xPositionPercentage = mouse[0] / self.dim.w;
          const showTooltipRightOfCursor = xPositionPercentage < 0.5;

          if (showTooltipRightOfCursor)
            return `translate(${mouse[0] + 32}, ${mouse[1] + size.height / 2})`;

          // show the tooltip left of the cursor
          return `translate(${mouse[0] - size.width - 35}, ${mouse[1] + size.height / 2})`;
        });
      })
      .on("click", (_: any, run: RunDto) => {
        this.clickHandler(run);
      })

    sSerieRects
      .merge(sSerieRectsEnter)
      .transition(t)
      .attr("x", (run: any) => {
        return this.x(moment(run.from).valueOf());
      })
      .attr("y", function (run: any) {
        const machineName = self.getMachineName(run.machineId);
        return self.y(machineName);
      })
      .attr("width", (run: any) => {
        return (
          this.x(moment(run.until).valueOf()) -
          this.x(moment(run.from).valueOf())
        );
      })
      .attr("height", this.y.bandwidth())
      .attr("fill", "#0097A7");
    sSerieRects.exit().remove();
    sSeries.exit().remove();
  }

  // private methods

  private init() {
    if (this.initDone)
      return;

    // set svg styles
    this.svg.attr("class", "ct-widget oee-availability full-width");

    this.resizeHandler = () => {
      this.resize();
    };
    this.t = d3.transition().duration(500);

    // svg structure
    this.svg.append("g").attr("class", "valid-periods");
    this.svg.append("g").attr("class", "series");
    this.svg.append("g").attr("class", "axes");
    this.svg
      .select("g.axes")
      .append("g")
      .attr("class", "x-axis");
    this.svg
      .select("g.axes")
      .append("g")
      .attr("class", "y-axis");
    this.svg.append("g").attr("class", "tooltip");
    this.svg
      .select("g.tooltip")
      .append("rect")
      .attr("class", "bg")
      .attr("rx", 2)
      .attr("ry", 2);
    this.svg
      .select("g.tooltip")
      .append("g")
      .attr("class", "content");
    this.svg
      .select("g.tooltip g.content")
      .append("text")
      .attr("class", "start")
      .attr("y", -49);
    this.svg
      .select("g.tooltip g.content")
      .append("text")
      .attr("class", "stop")
      .attr("y", -32);
    this.svg
      .select("g.tooltip g.content")
      .append("text")
      .attr("y", -15)
      .attr("class", "performance");
    this.svg
      .select("g.tooltip g.content")
      .append("text")
      .attr("y", +2)
      .attr("class", "production-order");

    // scales & axes
    this.x = d3.scaleTime();
    this.y = d3
      .scaleBand()
      .paddingInner(0.2)
      .paddingOuter(0.2);

    this.initDone = true;
    this.resize();
    window.addEventListener("resize", this.resizeHandler);
  }
  public resize() {
    if (!this.initDone) return;

    // get svg dimensions
    const bounds = this.svg.node().getBoundingClientRect();

    this.dim.w = bounds.width;
    this.dim.h = bounds.height;

    // this.dim.w = parseInt(this.svg.style("width"), 10);
    // this.dim.h = parseInt(this.svg.style("height"), 10);

    // scales
    this.x.range([
      this.margin.l + this.layout.paddingAxes,
      this.dim.w - this.margin.r,
    ]);
    this.y.range([
      this.margin.t,
      this.dim.h - this.margin.b - this.layout.paddingAxes,
    ]);

    // adjust components
    this.svg
      .select("g.x-axis")
      .attr("transform", `translate(0, ${this.dim.h - this.margin.b})`);
    this.svg
      .select("g.y-axis")
      .attr("transform", `translate(${this.margin.l}, 0)`);

    this.update(this.observedPeriods, this.runs, this.usedMachineIds);
  }

  private getUsedMachineIds(runs: RunDto[]): string[] {
    return runs
      .map((run: RunDto) => run.machineId)
      .filter((value: string, index: number, array: string[]) => {
        return array.indexOf(value) === index;
      });
  }
  private getMachineName(machineId: string) {
    const machine = this.getMachine(machineId);
    if (machine === null) return "Unknown machine";
    return machine.name;
  }

  private getMachine(machineId: string): MachineDetail | null {
    const machine = this.machines.find(
      (value: MachineDetail) => value.id === machineId
    );
    if (machine === undefined) return null;
    return machine;
  }

  private runsToMachineRuns(runs: RunDto[]): MachineRun[] {
    // Translatint the runs to runs per machine
    const machineIds = this.getUsedMachineIds(runs);
    const machineRuns: MachineRun[] = [];

    machineIds.forEach((machineId: string) => {
      const runsForThisMachine = runs.filter(
        (run: RunDto) => run.machineId === machineId
      );
      machineRuns.push({
        machineId: machineId,
        runs: runsForThisMachine,
      });
    });

    return machineRuns;
  }
}
