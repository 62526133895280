import script from "./ShiftDetailedOEE.vue?vue&type=script&lang=ts&setup=true"
export * from "./ShiftDetailedOEE.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QTd,QItem,QItemSection,QItemLabel});
