/* eslint-disable */
import { Pie } from './pie';

function layout(this: any) {
  const pie = this.svg
    .append('g')
    .attr('class', 'pie')
    .attr('stroke', 'white');

  pie
    .append('g')
    .attr('class', 'reset-button hidden')
    .attr('class');

  const text = this.svg
    .select('g.pie')
    .select('g.reset-button')
    .append('text');

  text
    .attr('stroke', 'black')
    .attr('stroke-width', '0.3')
    .attr('style', 'cursor: pointer;')
    .attr('font-family', 'sans-serif')
    .attr('font-size', 12)
    .attr('text-anchor', 'middle')
    .text('Reset \n view')
    .on('click', () => {
      this.onDetailSelection(null);
    });

  this.svg
    .append('g')
    .attr('class', 'legenda')
    .attr('font-family', 'sans-serif')
    .attr('font-size', 12)
    .attr('text-anchor', 'left');
}

export default {
  layout,
};
