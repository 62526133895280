/* eslint-disable */
import * as d3 from "d3";
import { Config } from "./models";

export class CTWGauge {
  // fields & constructor
  private svg: any;
  private resizeHandler: any = null;
  private config: Config;
  private initDone = false;
  private dim: any = { w: 0, h: 0 };
  private margin: any = { x: 16, t: 12, b: 0 };
  private r = 0;
  private overlayWidth = 8;
  private value = 0;
  private valuePrev = 0;
  constructor(container: string, config: Config) {
    this.svg = d3.select(`svg#${container}`);
    this.config = config;
    this.init();
  }
  public destroy() {
    window.removeEventListener("resize", this.resizeHandler);
  }

  // public methods
  public update(value?: number) {
    if (!this.initDone) return;
    
    // update values
    if (value !== null && value !== undefined) this.value = value;

    const val = this.getValPercentage(this.value);
    const valPrev = this.getValPercentage(this.valuePrev);

    this.svg.select("path.bg").attr(
      "d",
      d3.arc()({
        innerRadius: this.r * 0.8,
        outerRadius: this.r,
        startAngle: -Math.PI / 2,
        endAngle: Math.PI / 2,
      })
    );
    this.svg
      .select("text.value")
      .text(
        `${this.config.decimals
          ? this.value.toFixed(this.config.decimals)
          : this.value
        }${this.config.unit ? " " + this.config.unit : ""}`
      );
    this.svg.select("text.min").classed("error", this.errorUnderflow);
    this.svg.select("text.max").classed("error", this.errorOverflow);
    this.svg.select("g.info text").text(this.info);
    this.svg
      .select("g.info rect")
      .attr("height", 20)
      .attr("width", this.r)
      .attr("transform", `translate(${-this.r / 2}, -16)`)
      .style("display", this.state > 0 ? null : "none")
      .classed("error", this.state === 2 || this.state === 3)
      .classed("warning", this.state === 1);
    this.svg
      .select("path.marker.warning.min")
      .classed("active", this.warningMin);
    this.svg
      .select("path.marker.warning.max")
      .classed("active", this.warningMax);
    this.svg.select("path.marker.error.min").classed("active", this.errorMin);
    this.svg.select("path.marker.error.max").classed("active", this.errorMax);

    const path = d3.arc();
    this.svg
      .select("path.arc")
      .transition()
      .duration(500)
      .attrTween("d", () => {
        const start = {
          startAngle: -Math.PI / 2,
          endAngle: -Math.PI / 2 + Math.PI * valPrev,
        };
        const interpolate = d3.interpolate(start, {
          innerRadius: this.r * 0.8,
          outerRadius: this.r + this.overlayWidth,
          startAngle: -Math.PI / 2,
          endAngle: -Math.PI / 2 + Math.PI * val,
        });
        return (t: any) => path(interpolate(t));
      });
    this.svg
      .select("path.overlay")
      .transition()
      .duration(500)
      .attrTween("d", () => {
        const start = {
          startAngle: -Math.PI / 2,
          endAngle: -Math.PI / 2 + Math.PI * valPrev,
        };
        const interpolate = d3.interpolate(start, {
          innerRadius: this.r,
          outerRadius: this.r + this.overlayWidth,
          startAngle: -Math.PI / 2,
          endAngle: -Math.PI / 2 + Math.PI * val,
        });
        return (t: any) => path(interpolate(t));
      });

    this.valuePrev = this.value;
  }

  public resize() {
    if (!this.initDone) return;
    
    // get svg dimensions

    const bounds = this.svg.node().getBoundingClientRect();
    this.dim.w = bounds.width;
    this.dim.h = bounds.height;

    // calculate arc radius
    const w = this.dim.w - this.margin.x;
    const h = this.dim.h - this.margin.t - this.margin.b;
    this.r = w / 2 < h ? w / 2 : h;

    // position components
    this.svg
      .select("g.arc")
      .attr(
        "transform",
        `translate(${this.dim.w / 2}, ${this.dim.h - this.margin.b})`
      );
    this.svg
      .select("text.value")
      .attr(
        "transform",
        `translate(${this.dim.w / 2}, ${this.dim.h - this.margin.b - 4})`
      );
    this.svg
      .select("text.min")
      .attr(
        "transform",
        `translate(${this.dim.w / 2 - this.r * 0.8 + 8}, ${this.dim.h -
        this.margin.b -
        4})`
      );
    this.svg
      .select("text.max")
      .attr(
        "transform",
        `translate(${this.dim.w / 2 + this.r * 0.8 - 8}, ${this.dim.h -
        this.margin.b -
        4})`
      );
    this.svg
      .select("g.info")
      .attr(
        "transform",
        `translate(${this.dim.w / 2}, ${this.dim.h - this.margin.b - 32})`
      );
    if (this.config.minW)
      this.svg
        .select("g.arc path.marker.warning.min")
        .attr(
          "transform",
          `translate(0, ${-this.r}) rotate(${this.getMarkerPosition(
            this.config.minW
          )}, 0, ${this.r})`
        );
    if (this.config.maxW)
      this.svg
        .select("g.arc path.marker.warning.max")
        .attr(
          "transform",
          `translate(0, ${-this.r}) rotate(${this.getMarkerPosition(
            this.config.maxW
          )}, 0, ${this.r})`
        );
    if (this.config.minE)
      this.svg
        .select("g.arc path.marker.error.min")
        .attr(
          "transform",
          `translate(0, ${-this.r}) rotate(${this.getMarkerPosition(
            this.config.minE
          )}, 0, ${this.r})`
        );
    if (this.config.maxE)
      this.svg
        .select("g.arc path.marker.error.max")
        .attr(
          "transform",
          `translate(0, ${-this.r}) rotate(${this.getMarkerPosition(
            this.config.maxE
          )}, 0, ${this.r})`
        );

    this.update();
  }

  // private methods
  private init() {
    // set svg styles
    this.svg.attr("class", "ct-widget gauge");
    this.resizeHandler = () => {
      this.resize();
    };

    // arc
    const gArc = this.svg.append("g").attr("class", "arc");

    gArc.append("path").attr("class", "bg");
    gArc
      .append("path")
      .attr("class", "arc")
      .attr("fill", this.config.color);
    gArc.append("path").attr("class", "overlay");

    // markers
    const pathMarker = `M0 0L-${this.margin.t / 2} -${this.margin.t}L${this
      .margin.t / 2} -${this.margin.t}L0 0`;
    if (this.config.minW)
      gArc
        .append("path")
        .attr("class", "marker warning min")
        .attr("d", pathMarker);
    if (this.config.maxW)
      gArc
        .append("path")
        .attr("class", "marker warning max")
        .attr("d", pathMarker);
    if (this.config.minE)
      gArc
        .append("path")
        .attr("class", "marker error min")
        .attr("d", pathMarker);
    if (this.config.maxE)
      gArc
        .append("path")
        .attr("class", "marker error max")
        .attr("d", pathMarker);

    // labels
    if (this.config.tooltip) this.svg.append("title").html(this.config.tooltip);
    this.svg.append("text").attr("class", "value");
    this.svg
      .append("text")
      .attr("class", "min")
      .text(this.config.min);
    this.svg
      .append("text")
      .attr("class", "max")
      .text(this.config.max);
    const gInfo = this.svg.append("g").attr("class", "info");
    gInfo
      .append("rect")
      .attr("class", "info-bg")
      .attr("rx", 2)
      .attr("ry", 2);
    gInfo
      .append("text")
      .attr("class", "info")
      .text("info");

    this.initDone = true;

    this.resize();
    window.addEventListener("resize", this.resizeHandler);
  }

  private getValPercentage(val: number) {
    if (!val) return 0;
    if (val < this.config.min) return 0;
    else if (val > this.config.max) return 1;
    else return (val - this.config.min) / (this.config.max - this.config.min);
  }
  private getMarkerPosition(val: number) {
    return (this.getValPercentage(val) - 0.5) * 180.0;
  }

  // properties
  get errorUnderflow() {
    if (this.config.ignoreUnderflow === true) return false;

    return this.value < this.config.min;
  }
  get errorOverflow() {
    if (this.config.ignoreOverflow === true) return false;
    return this.value > this.config.max;
  }
  get warningMin() {
    return this.config.minW && this.value < this.config.minW;
  }
  get warningMax() {
    return this.config.maxW && this.value < this.config.maxW;
  }
  get errorMin() {
    return this.config.minE && this.value < this.config.minE;
  }
  get errorMax() {
    return this.config.maxE && this.value < this.config.maxE;
  }
  get state() {
    if (this.errorUnderflow || this.errorOverflow) return 3;
    if (this.errorMin || this.errorMax) return 2;
    if (this.warningMin || this.warningMax) return 1;
    return 0;
  }
  get info() {
    if (this.errorUnderflow) return "UNDERFLOW";
    if (this.errorMin) return "ERROR: LOW";
    if (this.warningMin) return "WARNING: LOW";
    if (this.errorOverflow) return "OVERFLOW";
    if (this.errorMax) return "ERROR: HIGH";
    if (this.warningMax) return "WARNING: HIGH";
    return "";
  }
}
