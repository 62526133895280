/* eslint-disable */
// libraries
import * as d3 from 'd3';

// models
import { Layout, PieData, PieSettings } from './models';

// extra functionalities
import init from './pie.init';
import update from './pie.update';

export class Pie {

  // protected properties
  protected svg: d3.Selection<d3.BaseType, unknown, HTMLElement, any>;
  protected settings!: PieSettings;
  protected layout!: Layout;
  protected arcGenerator!: d3.Arc<any, d3.DefaultArcObject>;
  protected pieGenerator!: d3.Pie<any, number | { valueOf(): number }>;
  protected colorScale!: d3.ScaleOrdinal<string, unknown>;
  protected data: PieData[] = [];

  // event handlers
  protected detailRequestEvent: ((key: string | null) => void) | undefined;
  // fields
  private initDone = false;

  // constructor
  constructor(
    svgSelector: string,
    settingsBuilder?: (settings: PieSettings) => PieSettings,
  ) {
    this.svg = d3.select(svgSelector);
    this.settings = this.createDefaultSettings();

    if (settingsBuilder !== undefined) {
      this.settings = settingsBuilder(this.settings);
    }

    this.init();
  }

  // public methods
  public setData(data: PieData[]) {
    this.data = data;
    this.update();
  }

  public setDetailRequestEventHandler(
    detailRequestEventHandler: (key: string | null) => void,
  ) {
    this.detailRequestEvent = detailRequestEventHandler;
  }

  protected onDetailSelection(key: string | null) {
    if (!this.detailRequestEvent) return;

    const hideResetButton = key === null;

    this.svg
      .select('g.pie')
      .select('g.reset-button')
      .classed('hidden', hideResetButton);

    this.detailRequestEvent(key);
  }

  // private methods
  private createDefaultSettings(): PieSettings {
    return {
      margin: 16,
      splitWidthFraction: 0.5,
      legendLeftMargin: 50,
    };
  }

  private init() {
    
    init.layout.bind(this)();
    
    this.pieGenerator = d3.pie().value((d: any) => (d as PieData).value);

    this.initDone = true;
    this.resize();
  }

  private resize() {
    if (!this.initDone) return;

    // determine the size of the svg
    const svgHeight = parseFloat(this.svg.style('height'));
    const svgWidth = parseFloat(this.svg.style('width'));

    this.layout = {
      height: svgHeight - 2 * this.settings.margin,
      width: svgWidth - 2 * this.settings.margin,
    };
    
    // determine the outer radius of the donut
    // the diameter is the minimum of one of these two parameters
    //   * the height
    //   * the width-part of the area (split by the 'splitWidthFraction')

    const maxRadius =
      Math.min(
        this.layout.width * this.settings.splitWidthFraction,
        this.layout.height,
      ) / 2;

    this.arcGenerator = d3
      .arc()
      .innerRadius(maxRadius * 0.4)
      .outerRadius(maxRadius);

    // position components

    // the x-position of the pie (anchored in the middle)
    //   One left margin + half of the pie-width.

    // the y-position (anchored in the middle) is half of the height + margin

    const pieWidth = this.layout.width * this.settings.splitWidthFraction;

    this.svg.select('g.pie').attr(
      'transform',
      `translate(
          ${this.settings.margin + pieWidth / 2},
          ${this.settings.margin + this.layout.height / 2})`,
    );

    // the x-position of the pie (anchored in the middle)
    this.svg.select('g.legenda').attr(
      'transform',
      `translate(
          ${this.settings.margin + pieWidth + this.settings.legendLeftMargin},
          ${this.settings.margin})`,
    );
  }

  private update() {
    if (!this.initDone) return;

    // generate the random colors
    this.colorScale = d3
      .scaleOrdinal()
      .domain(this.data.map((d) => d.key))
      .range(
        d3
          .quantize((t) => {
            if (isNaN(t)) t = 0;

            return d3.interpolateWarm(t * 0.8 + 0.1);
          }, this.data.length)
          .reverse(),
      );
      
    update.pie.bind(this)();
    update.labels.bind(this)();
  }
}
