/* eslint-disable */
import { PieData } from './models';

function pie(this: any): void {
  // Select all paths and attach them to the piedata, defining 'key' as the key
  const all = this.svg
    .select('g.pie')
    .selectAll('path')
    .data(this.pieGenerator(this.data as any), (d: any) => {
      return (d.data as PieData).key;
    });

  // Add the path for the new entries
  const newPaths = all
    .enter()
    .append('path')
    .on('click', (d: any) => {
      const data = d.data as PieData;
      this.onDetailSelection(data.key);
    });

  newPaths.append('title');
  // Do for all entries: recalculate path and color
  all
    .merge(newPaths as any)
    .attr('d', this.arcGenerator as any)
    .attr('fill', (d: any, i: number) => {
      const data = d.data as PieData;
      const predefinedColor = this.colorScale(data.key);

      if (data.color) return data.color;
      else if (typeof predefinedColor === 'string') {
        return predefinedColor.toString();
 } else return 'black';
    });

  all
    .merge(newPaths as any)
    .selectAll('title')
    .text((d: any) => {
      const data = d.data as PieData;

      if (!data.tooltip) return '';
      return data.tooltip;
    });

  all.exit().remove();
}

function labels(this: any) {
  const l = this.svg
    .select('g.legenda')
    .selectAll('g.record')
    .data(this.pieGenerator(this.data as any), (d: any) => {
      return (d.data as PieData).key;
    });
  const lE = l
    .enter()
    .append('g')
    .attr('class', 'record')
    .attr('style', 'cursor: arrow;')
    .on('click', (d: any) => {
      const data = d.data as PieData;
      this.onDetailSelection(data.key);
    }) as any;
  lE.append('circle')
    .attr('cx', 16)
    .attr('cy', 16)
    .attr('r', 10);
  lE.append('text');
  lE.select('text')
    .append('tspan')
    .attr('x', 0)
    .attr('y', '1em')
    .attr('font-weight', 'bold')
    .text((d: any) => {
      const dp = d.data as PieData;
      return dp.key;
    });
  lE.select('text')
    .append('tspan')
    .attr('class', 'value')
    .attr('x', 0)
    .attr('y', '2em')
    .attr('fill-opacity', 0.7);
  l.merge(lE).attr(
    'transform',
    (d: any, i: number) => `translate(0, ${i * 32})`,
  );
  l.merge(lE)
    .select('circle')
    .attr('fill', (d: any, i: number) => {
      const data = d.data as PieData;
      const predefinedColor = this.colorScale(data.key);

      if (data.color) return data.color;
      else if (typeof predefinedColor === 'string') {
        return predefinedColor.toString();
 } else return 'black';
    });
  l.merge(lE)
    .select('text')
    .attr('transform', (d: any, i: number) => `translate(32, 0)`);
  l.merge(lE)
    .select('tspan.value')
    .text((d: any) => {
      const dp = d.data as PieData;
      if (dp.legendaDescription) return dp.legendaDescription;
      else return '';
    });
  l.exit().remove();
}

export default {
  pie,
  labels,
};
