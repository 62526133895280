import script from "./OEEBarChart.vue?vue&type=script&lang=ts&setup=true"
export * from "./OEEBarChart.vue?vue&type=script&lang=ts&setup=true"

import "./OEEBarChart.vue?vue&type=style&index=0&id=2da8848c&lang=scss"

const __exports__ = script;

export default __exports__
import QResizeObserver from 'quasar/src/components/resize-observer/QResizeObserver.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QResizeObserver});
